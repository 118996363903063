// OndemandPage.js

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useParams, useLocation } from 'react-router-dom';
import {
  FaPlay, FaStop, FaMicrophone, FaMicrophoneSlash,
} from 'react-icons/fa';
import '../../css/vehicle_page/OndemandPage.css';
import i18n from 'i18next';

const OndemandPage = () => {
  const { deviceId } = useParams(); // Get device ID from route parameters
  const [localStream, setLocalStream] = useState(null); // Add state to manage local audio stream
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMicrophoneEnabled, setIsMicrophoneEnabled] = useState(true);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [iceConnectionState, setIceConnectionState] = useState('');
  const [iceGatheringState, setIceGatheringState] = useState('');
  const [signalingState, setSignalingState] = useState('');
  const [offerSDP, setOfferSDP] = useState('');
  const [answerSDP, setAnswerSDP] = useState('');
  const [dataChannelLogs, setDataChannelLogs] = useState([]);
  const [isStarted, setIsStarted] = useState(false);
  const [isDisconnected, setIsDisconnected] = useState(false); // Track WebSocket connection state
  const location = useLocation(); // Access location to retrieve passed state
  const device = location.state?.device; // Safely access the device object

  const websocketRef = useRef(null);
  const peerConnectionRef = useRef(null);
  const dataChannelRef = useRef(null);
  const videoRef = useRef(null);
  const pingTimeoutRef = useRef(null);
  const connectTimerRef = useRef(null);

  const generateRandomId = (length) => {
    const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    return `web-${Array.from({ length }, () => chars.charAt(Math.floor(Math.random() * chars.length))).join('')}`;
  };

  const resetPingTimeout = () => {
    if (pingTimeoutRef.current) clearTimeout(pingTimeoutRef.current);
    pingTimeoutRef.current = setTimeout(() => {
      console.log('Ping not received in 5 seconds. Resetting connections.');
      stopConnection(); // Reset WebRTC and WebSocket connections
      websocketRef.current.close();
      setIsDisconnected(true);
    }, 5000); // 5 seconds timeout
  };

  // Add Data Channel Log
  const addDataChannelLog = (message) => {
    setDataChannelLogs((prevLogs) => [...prevLogs, message]);
  };

  // Create PeerConnection
  const createPeerConnection = () => {
    const config = {
      bundlePolicy: 'max-bundle',
      iceServers: [{ urls: ['stun:stun.l.google.com:19302'] }],
    };

    const pc = new RTCPeerConnection(config);

    pc.oniceconnectionstatechange = () => setIceConnectionState(pc.iceConnectionState);

    pc.onicegatheringstatechange = () => setIceGatheringState(pc.iceGatheringState);

    pc.onsignalingstatechange = () => setSignalingState(pc.signalingState);

    pc.ontrack = (event) => {
      const video = videoRef.current;
      if (video.srcObject !== event.streams[0]) {
        video.muted = false; // Ensure the video element is not muted
        video.volume = 1.0; // Set volume to maximum
        video.srcObject = event.streams[0];
      }

      video.onloadedmetadata = () => {
        video.play().catch((error) => {
          console.error('Error during video playback:', error);
        });
      };
    };

    pc.ondatachannel = (event) => {
      const dc = event.channel;
      dataChannelRef.current = dc;

      dc.onopen = () => addDataChannelLog('Data channel open');
      dc.onmessage = (event) => {
        const message = event.data;
        addDataChannelLog(`Message: ${message}`);
      };
      dc.onclose = () => addDataChannelLog('Data channel closed');
    };

    return pc;
  };

  // Handle Offer
  const handleOffer = async (offer) => {
    const pc = createPeerConnection();
    peerConnectionRef.current = pc;

    await pc.setRemoteDescription(offer);
    await startAudioCapture();
    await sendAnswer(pc);
  };

  useEffect(() => {
    const handleOnline = () => {
      console.log('Network resumed. Attempting to reconnect...');
      setIsDisconnected(true); // Trigger reconnection logic
      attemptReconnect();
    };

    const handleOffline = () => {
      console.log('Network disconnected.');
      setIsDisconnected(true);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  // Stop WebRTC Connection
  const stopConnection = () => {
    setIsStarted(false);
    if (pingTimeoutRef.current) clearTimeout(pingTimeoutRef.current);

    // Close Data Channel
    if (dataChannelRef.current) {
      dataChannelRef.current.close();
      dataChannelRef.current = null;
    }

    // Close Transceivers
    if (peerConnectionRef.current?.getTransceivers) {
      peerConnectionRef.current.getTransceivers().forEach((transceiver) => {
        if (transceiver.stop) {
          transceiver.stop();
        }
      });
    }

    // Close Local Audio/Video Tracks
    peerConnectionRef.current?.getSenders().forEach((sender) => {
      sender.track?.stop();
    });

    // Close Peer Connection
    if (peerConnectionRef.current) {
      peerConnectionRef.current.close();
      peerConnectionRef.current = null;
    }
  };

  const attemptReconnect = () => {
    if (websocketRef.current && websocketRef.current.readyState === WebSocket.OPEN) {
      console.log('WebSocket is already connected. No need to reconnect.');
      return;
    }

    if (connectTimerRef.current) {
      clearTimeout(connectTimerRef.current); // Clear any existing timer
    }

    connectTimerRef.current = setTimeout(() => {
      if (isDisconnected) {
        console.log('Attempting to reconnect...');
        connectWebSocket();

        // Recursively call `attemptReconnect` if still disconnected
        setTimeout(() => {
          if (websocketRef.current?.readyState !== WebSocket.OPEN) {
            attemptReconnect(); // Retry if WebSocket is still not connected
          }
        }, 5000); // Retry every 5 seconds
      }
    }, 5000); // Initial delay before retrying
  };

  const connectWebSocket = () => {
    if (websocketRef.current && websocketRef.current.readyState !== WebSocket.CLOSED) {
      console.log('WebSocket is not closed. Skipping reconnection.');
      return;
    }

    const clientId = generateRandomId(10);
    const ws = new WebSocket(`wss://ondemand.cloud.mindtronicai.com/${clientId}`);
    websocketRef.current = ws;

    ws.onopen = () => {
      console.log('WebSocket connected');
      setIsDisconnected(false);
      resetPingTimeout(); // Reset ping timeout when WebSocket connects
    };

    ws.onmessage = async (event) => {
      if (typeof event.data !== 'string') return;
      const message = JSON.parse(event.data);
      if (message.type === 'offer') {
        setOfferSDP(message.sdp);
        await handleOffer(message);
      } else if (message.type === 'ping') {
        resetPingTimeout();
        websocketRef.current.send(
          JSON.stringify({
            type: 'pong',
          }),
        );
      } else if (message.type === 'disconnected') {
        stopConnection();
      }
    };

    ws.onclose = () => {
      console.log('WebSocket disconnected');
      setIsDisconnected(true);
      attemptReconnect();
    };

    ws.onerror = () => {
      console.log('WebSocket error');
      setIsDisconnected(true);
      attemptReconnect();
    };
  };


  // Initialize WebSocket
  useEffect(() => {
    connectWebSocket();
    attemptReconnect();

    return () => {
      if (websocketRef.current) websocketRef.current.close();
      if (pingTimeoutRef.current) clearTimeout(pingTimeoutRef.current);
      if (connectTimerRef.current) clearTimeout(connectTimerRef.current);
      if (localStream) {
        localStream.getTracks().forEach((track) => track.stop());
      }
    };
  }, []);

  useEffect(() => () => {
    // Cleanup local stream
    if (localStream) {
      localStream.getTracks().forEach((track) => track.stop());
    }
  }, [localStream]);

  function sendRequest() {
    websocketRef.current.send(JSON.stringify({
      id: deviceId,
      type: 'request',
      index: selectedTabIndex,
    }));
  }

  // Start WebRTC Connection
  const startConnection = () => {
    setIsStarted(true);
    sendRequest();
    setIsMicrophoneEnabled(true);
  };

  // Start Audio Capture
  const startAudioCapture = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setLocalStream(stream); // Save the local stream in state

      const pc = peerConnectionRef.current;
      stream.getTracks().forEach((track) => pc.addTrack(track, stream));
    } catch (error) {
      console.error('Error capturing audio:', error);
    }
  };

  // Function to add G.711 codecs to SDP
  function addG711ToSDP(sdp) {
    // Split the SDP into lines
    const sdpLines = sdp.split('\r\n');
    const mLineIndex = sdpLines.findIndex((line) => line.startsWith('m=audio'));

    if (mLineIndex === -1) {
      console.error('No m=audio line found in SDP');
      return sdp;
    }

    // Add payload types for PCMU (0) and PCMA (8)
    let mLine = sdpLines[mLineIndex];
    mLine += ' 0'; // Add payload types 0 and 8 to m=audio line
    sdpLines[mLineIndex] = mLine;

    // Add rtpmap lines for PCMU and PCMA if not already present
    if (!sdp.includes('a=rtpmap:0 PCMU/8000/1')) {
      sdpLines.splice(mLineIndex + 1, 0, 'a=rtpmap:0 PCMU/8000/1');
    }
    return sdpLines.join('\r\n');
  }

  const waitGatheringComplete = (pc) => new Promise((resolve) => {
    if (pc.iceGatheringState === 'complete') {
      resolve();
    } else {
      const onIceGatheringStateChange = () => {
        if (pc.iceGatheringState === 'complete') {
          pc.removeEventListener('icegatheringstatechange', onIceGatheringStateChange);
          resolve();
        }
      };
      pc.addEventListener('icegatheringstatechange', onIceGatheringStateChange);
    }
  });

  // Updated sendAnswer function
  const sendAnswer = async (pc) => {
    try {
      const answer = await pc.createAnswer();
      answer.sdp = addG711ToSDP(answer.sdp);

      await pc.setLocalDescription(answer);
      await waitGatheringComplete(pc);
      pc.getTransceivers().forEach((transceiver) => {
      });

      setAnswerSDP(pc.localDescription.sdp);
      websocketRef.current.send(
        JSON.stringify({
          id: deviceId,
          type: pc.localDescription.type,
          sdp: pc.localDescription.sdp,
        }),
      );
    } catch (error) {
      console.error('Error sending answer:', error);
    }
  };

  const toggleMicrophone = async () => {
    if (!localStream) {
      try {
        // If no local stream exists, create one
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        localStream.getAudioTracks().forEach((track) => {
          track.enabled = !isMicrophoneEnabled;
        });
        setLocalStream(stream); // Save the local stream in state
      } catch (error) {
        console.error('Error accessing microphone:', error);
      }
    } else {
      // Toggle the enabled state of each audio track
      localStream.getAudioTracks().forEach((track) => {
        track.enabled = !isMicrophoneEnabled;
      });
    }
    setIsMicrophoneEnabled(!isMicrophoneEnabled); // Update UI state based on track enabled state
  };

  const tabLabels = [i18n.t('dvr_front'), i18n.t('dvr_rear'), i18n.t('dvr_cabin')];

  const renderTabButtons = () => (
    <div className="tab-buttons">
      {tabLabels.map((label, index) => (
        <button
          key={label}
          type="button"
          onClick={() => {
            setSelectedTabIndex(index);
            // Send a message through WebSocket
            if (websocketRef.current && websocketRef.current.readyState === WebSocket.OPEN) {
              websocketRef.current.send(
                JSON.stringify({
                  id: deviceId,
                  type: 'channel',
                  index, // Send the index of the selected tab
                }),
              );
            } else {
              console.error('WebSocket is not open.');
            }
          }}
          className={selectedTabIndex === index ? 'active' : ''}
        >
          {label}
        </button>
      ))}

    </div>
  );

  const renderWarning = () => {
    if (isDisconnected) {
      return (
        <div className="warning">
          <p>{i18n.t('vod_connection_error')}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="ondemand-page">
      <h1>{i18n.t('vod_title')}</h1>
      {renderWarning()}
      <div>
        <h2>
          {device.car_num}
        </h2>
      </div>
      <div>{renderTabButtons()}</div>
      <div className="video-container">
        <video ref={videoRef} autoPlay playsInline className="video">
          <track kind="captions" />
        </video>
      </div>
      <div className="tab-buttons">
        {!isStarted && (
        <button type="button" onClick={startConnection} className="icon-button" disabled={isDisconnected}>
          <FaPlay size={30} color="#fff" />
        </button>
        )}
        {isStarted && (
        <button type="button" onClick={stopConnection} className="icon-button" disabled={isDisconnected}>
          <FaStop size={30} color="#fff" />
        </button>
        )}
        {isStarted && (
        <button
          type="button"
          onClick={toggleMicrophone}
          className="icon-button"
          disabled={isDisconnected}
        >
          {isMicrophoneEnabled ? <FaMicrophone size={30} color="#fff" /> : <FaMicrophoneSlash size={30} color="#fff" />}
        </button>
        )}

      </div>
    </div>
  );
};

OndemandPage.defaultProps = {
  device: {
    device_id: '',
    car_num: '',
    driver_name: '',
    speed: 0,
    latitude: 0,
    longitude: 0,
  },
};

OndemandPage.propTypes = {
  device: PropTypes.shape({
    device_id: PropTypes.string.isRequired,
    car_num: PropTypes.string,
    driver_name: PropTypes.string,
    speed: PropTypes.number,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  }),
};

export default OndemandPage;
